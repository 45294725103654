<template>
  <login-form @clickLogin="clickLogin($event)" @signup="signup($event)"></login-form>
</template>
<script>
import AppLogin from "../../library/auth/login";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
//import { nullOrUndefined } from "../../../../plugins/libs.js";

export default {
  components: {
    "login-form": AppLogin,
  },
  data() {
    return {
      valid: false,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      user: {},
    };
  },
  mounted() {
    console.log("mounted login");
    console.log(this.$cookies.get("Profile"));
    if (this.$cookies.get("Auth").authType != "Admin") {
      this.$cookies.set("Auth", {});
      this.$cookies.set("Profile", {});
    } else if (
      this.$cookies.get("Profile").member[0].member_type == "Admin" &&
      this.$cookies.get("Auth").authType == "Admin"
    ) {
      this.$router.push("/admin/dashboard");
    }
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      // getInfo: "BFC/getDoctorInfo",
    }),
  },
  methods: {
    ...mapActions({
      //auth: "BFC/adminAuth",
      //setInfo: "BFC/setDoctorInfo",
      setAllCases: "BFC/setAllCases",
      setAuth: "BFC/setAuth",
    }),
    async clickLogin(payload) {
      console.log("login");
      console.log(payload);
      try {
        // //let payload
        // let payloadAuth = {
        //   _id: "",
        //   authType: "Admin",
        //   token: "",
        // };
        // await this.setAuth(payloadAuth);
        //await this.listCases();
        // this.$router.push("/admin/dashboard");
        await axios
          .post(
            this.url + "/api/bfc/v1/admin/signin",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              let payloadAuth = {
                _id: response.data._id,
                authType: "Admin",
                lastLogin: new Date(),
                token: "",
              };
              //await this.auth(payload);
              //await this.setInfo(response.data);
              this.$cookies.set("Auth", payloadAuth);
              this.$cookies.set("Profile", response.data.data);
              //await this.setAuth(payloadAuth);
              //await this.auth(payload);
              //await this.setInfo(response.data);
              await this.setAuth(payloadAuth);
              //await this.listCases();
              // doctor info

              //console.log(this.getInfo);
              this.$router.push("/admin/dashboard");
            } else {
              // show error
              console.log("error response");
              alert("Login failed!");
            }
          });
      } catch (e) {
        //
        console.log(e);
        alert("Login failed!");
      }
    },

    signup() {
      //console.log(e);
      this.$router.push("/admin/sign-up");
    },
  },
};
</script>
